#footerCon {
  background: black;
  overflow-x: auto;
  padding-top: 15%;
}

.footer {
  display: flex;
  align-items: start;
}

.copyright {
  color: white;
  /* display: inline-block; */
  text-align: center;
}

.socialHeader {
  color: white;
  font-size: 24px;
  margin-left: 4%;
}

.instagram {
  margin-left: 7%;
  max-width: 75px;
  max-height: 75px;
}

.discord-icon {
  max-width: 60px;
  max-height: 60px;
  margin-left: 7.5%;
}

@media only screen and (max-width: 1024px) {
  .discord-icon {
    padding-bottom: 10px;
    margin-left: 8%;
  }
}

@media only screen and (max-width: 540px) {
  .discord-icon {
    margin-left: 9.2%;
  }
}

@media only screen and (max-width: 415px) {
  .discord-icon {
    margin-left: 10%;
  }
}
