#privacy{
    background: black;
}

.privacy-container {
    padding: 5%;
}

.privacy-title {
    color: white;
    font-size: 50px;
    font-weight: 700;
    padding-bottom: 5%;
}

.sub-head {
    color: rgb(192, 91, 229);
    font-size: 35px;
    font-weight: 500; 
}

.segment {
    color: white;
    font-size: 30px;
    font-weight: 400;
    padding-top: 2%;
}

.policy-text {
    color: white;
    font-size: 20px;
}

.space {
    padding-top: 2%;
}

.underline {
    text-decoration: underline;
    margin-right: 8px;
}

@media only screen and (max-width: 820px) {
    .privacy-title {
        font-size: 35px;
    }
}

@media only screen and (max-width: 500px) {
    .privacy-title {
        font-size: 20px;
        }
        .sub-head {
            font-size: 18px;
        }
        .policy-text {
            font-size: 16px;
        }
}