#resources {
  overflow-x: auto;
}

.resources {
  background-color: rgb(0, 0, 1);
}

.resource-title {
  color: white;
  font-size: 55px;
  padding-top: 8%;
  display: flex;
  justify-content: center;
  text-decoration: overline rgb(164, 35, 228);
}

.imageMap {
  display: flex;
  text-align: justify;
  justify-content: center;
  padding: 6%;
}

.imageCon {
  flex: 30%;
  max-width: 33.33%;
  max-height: 33.33%;
  margin-top: 2%;
}

.imageCard {
  margin-left: 5%;
  max-width: 150px;
  max-height: 150px;
}

.imageDesc {
  padding-top: 5px;
  color: white;
  font-size: 16px;
  width: 90%;
  text-align: justify;
  word-spacing: 0.5px;
}

@media only screen and (max-width: 830px) {
  .resource-title {
    font-size: 45px;
  }

  .imageDesc {
    font-size: 14px;
    width: 100%;
  }

  .imageCard {
    max-width: 125px;
    max-height: 125px;
  }
}

@media only screen and (max-width: 600px) {
  .imageCon {
    flex: 50%;
    max-width: 50%;
    max-height: 50%;
  }
}

@media only screen and (max-width: 440px) {
  .imageCon {
    flex: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  .imageCard {
    margin-left: 35%;
  }
  .imageDesc {
   text-align: center;
  }
}
