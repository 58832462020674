#mission{
    background-color: rgb(1, 0, 2);
}

.purple {
    color: rgb(163, 72, 229);
}

.missionTitle {
    will-change: scroll-position;
    color: white;
    font-size: 50px;
    padding-top: 5%;
    margin-left: 10%;
}

.missionTitle2 {
    color: white;
    font-size: 50px;
    padding-top: 5%;
    margin-left: 25%;
}

.missionTitle3 {
    color: white;
    font-size: 50px;
    padding-top: 5%;
    margin-left: 40%;
}

.statement {
    color: white;
    font-size: 22px;
    font-family: sans-serif;
    max-width: 600px;
    margin-left: 15%;
    padding-top: 2%;
}

.statement2 {
    color: white;
    font-size: 22px;
    font-family: sans-serif;
    max-width: 600px;
    margin-left: 30%;
    padding-top: 2%;
}

.statement3 {
    color: white;
    font-size: 22px;
    font-family: sans-serif;
    max-width: 600px;
    margin-left: 45%;
    padding-top: 2%;
}

@media only screen and (max-width: 1025px) {
    .missionTitle, .missionTitle2, .missionTitle3 {
        font-size: 40px;
    }
    .statement, .statement2, .statement3 {
        font-size: 20px;
        max-width: 500px;
    }
}

@media only screen and (max-width: 821px) {
    .statement {
        margin-left: 10% !important;
    }
    .statement2 {
        margin-left: 20% !important;
    }
    
    .statement3 {
        margin-left: 35% !important;
    }
}

@media only screen and (max-width: 725px) {
    .missionTitle, .missionTitle2, .missionTitle3 {
        font-size: 30px;
        text-align: center;
        margin-left: 0 !important;
    }
    
    .statement, .statement2, .statement3 {
        text-align: center;
        font-size: 16px;
       margin-left: 0 !important;
    }


    .statement-container {
      padding: 10%;
 
    }
}