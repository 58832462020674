*{
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
  /* overflow-x: auto; */
 }
 
/* for mobile */
  [class*="col-"] {
    width: 100%;
  }

@media onlyscreen and (min-width: 768px){
  /* for desktop */
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}
}

#home {
  background-color: black;
}

.loadingQue {
  background-color: black;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

